<template>
    <div class="card card-flush py-4">
        <div class="card-header">
            <div class="card-title">
                <h2>Imagen</h2>
            </div>
        </div>
        <div class="card-body text-center pt-0">
            <div class="image-input image-input-empty image-input-outline image-input-placeholder mb-3" data-kt-image-input="true">
                <!-- <img class="image-input-wrapper w-150px h-150px" src="media/products/perfil-ranurado-aluminio.jpg" alt=""> -->
                <img class="image-input-wrapper w-150px h-150px" :src="getImageLoaded" alt="">
                <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                    <i class="bi bi-pencil-fill fs-7"></i>
                    <input type="file" name="avatar" accept="image/png, image/jpg, image/jpeg" ref="imageLoader" @change="onChangeImageLoaded"/>
                    <input type="hidden" name="avatar_remove" />
                </label>
                <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
                    <i class="bi bi-x fs-2"></i>
                </span>
                <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar">
                    <i class="bi bi-x fs-2"></i>
                </span>
            </div>
            <div class="text-muted fs-7">Seleccione la imagen del artículo. Sólo se admiten archivos de tipo png, jpg y jpeg.</div>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
    name: "add-image",
    setup(props, context) {
        let imageLoader = ref(null);
        let imageLoaded = ref('');
        let imageArray = [];

        const getImageLoaded = computed(() => imageLoaded.value);

        const onChangeImageLoaded = () => {
            let image = imageLoader.value.files[0];
            let loader = new FileReader();
            loader.onloadend = () => {
                imageLoaded.value = loader.result;
            };

            if(image && image instanceof Blob) {
                loader.readAsDataURL(image);
            }
            
            context.emit("onImageLoaded", image);
            //saveImage();
        }

        const saveImage = () => {
            let image = imageLoader.value.files[0];
            let loader = new FileReader();
            loader.onloadend = () => {
                imageArray = new Uint8Array(loader.result);
                context.emit("onImageLoaded", imageArray);
            };

            if(image && image instanceof Blob) {
                loader.readAsArrayBuffer(image);
            }
        }

        return {
            imageLoader
            //Computed
            , getImageLoaded
            //Functions
            , onChangeImageLoaded
        }
    },
})
</script>
