<i18n>
{
    "es":{
        "TITLES":{
            "EQUIVALENCIES":"Equivalencias"
        }
    }
}
</i18n>
<template>
    <ul class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2">
        <li class="nav-item">
            <a class="nav-link text-active-primary pb-4 active" @click="changeTab(1)" data-bs-toggle="tab" href="#tab_article_description">Descripción</a>
        </li>
        <li class="nav-item">
            <a class="nav-link text-active-primary pb-4" @click="changeTab(2)" data-bs-toggle="tab" href="#tab_article_pricing">Precios</a>
        </li>
        <li class="nav-item">
            <a class="nav-link text-active-primary pb-4" @click="changeTab(3)" data-bs-toggle="tab" href="#tab_article_general">Generales</a>
        </li>
         <li class="nav-item">
            <a class="nav-link text-active-primary pb-4" @click="changeTab(4)" data-bs-toggle="tab" href="#tab_equivalencies">{{$t("TITLES.EQUIVALENCIES")}}</a>
        </li>
    </ul>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: "edit-nav-tabs",
    setup(props, context) {
        /** METHODS */
        const changeTab = (tabId) => {
            context.emit("onChangeTab", tabId);
        }

        return {
            /** METHODS */
            changeTab
        }
    },
})
</script>
